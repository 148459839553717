<template>
  <q-form
    ref="registerForm"
    class="login"
    @submit="submitForm"
  >
    <q-card-section>
      <div class="q-pa-sm">
        <h5 class="q-mb-none q-mt-xs text-weight-bold">
          Register
        </h5>
      </div>
    </q-card-section>

    <q-card-section
      v-if="getServerError('_') != true"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm doc-note doc-note-danger"
        inset
      >
        {{ getServerError('_') }}
      </div>
    </q-card-section>

    <q-card-section
      v-if="!!serverMessage"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm doc-note doc-note-success"
        inset
        v-html="serverMessage"
      />
    </q-card-section>

    <q-card-section
      v-if="!!serverErrorMessage"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm text-red"
        inset
      >
        {{ serverErrorMessage }}
      </div>
    </q-card-section>

    <q-card-section>
      <div class="q-pa-sm q-gutter-y-sm column">
        <q-input
          ref="first_name"
          v-model="first_name"
          label="First Name"
          :rules="[val => !!val || 'Field is required', val => val.length >= 2 || 'Field is too short', val => getServerError('first_name')]"
          @blur="clearServerError('first_name')"
        />

        <q-input
          ref="last_name"
          v-model="last_name"
          label="Last Name"
          :rules="[val => !!val || 'Field is required', val => val.length >= 2 || 'Field is too short', val => getServerError('last_name')]"
          @blur="clearServerError('last_name')"
        />

        <q-input
          ref="email"
          v-model="email"
          label="Email"
          :rules="[val => !!val || 'Field is required', val => getServerError('email')]"
          @blur="clearServerError('email')"
        />

        <q-input
          v-model="password"
          :type="isPwd ? 'password' : 'text'"
          label="Password"
          :rules="[val => !!val || 'Field is required', val => val.length >= 6 || 'Field is too short']"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>


        <q-select
          v-model="account_type"
          style="scroll-margin-top: 50px"
          :options="accountTypes"
          :default="'jobseeker'"
          dense
          label="Account Type"
          emit-value
          map-options
          :rules="[
            val => !!val || 'Field is required',
          ]"
          >
        </q-select>

        <q-btn
          type="submit"
          color="primary"
          no-caps
          :loading="loading"
        >
          Register
        </q-btn>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions align="between">
      <q-btn
        to="/auth/login"
        color="secondary"
        flat
        no-caps
      >
        Log In
      </q-btn>
      <q-btn
        to="/auth/forgot"
        color="secondary"
        flat
        no-caps
      >
        Forgot Password
      </q-btn>
    </q-card-actions>
  </q-form>
</template>

<script>
import { serverErrorMixin } from 'src/mixins/server_error.mixin';
import ApiService from 'src/services/api.service';

export default {
  mixins: [serverErrorMixin],
  data() {
    return {
      accountTypes: [
        { label: 'Job Seeker', value: 'jobseeker' },
        { label: 'Employer', value: 'organization' },
      ],
      account_type: 'jobseeker',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      isPwd: true,
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      const { first_name, last_name, email, password, account_type } = this;
      console.log('register called', email, password, first_name, last_name);

      this.loading = true;
      this.clearServerErrors();
      try {
        const apiData = await ApiService.post('/api/auth/register', {
          first_name,
          last_name,
          email,
          password,
          account_type,
        });

        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.password = null;
        this.$refs.registerForm.reset();
        this.serverMessage = apiData.data.message;

        // window.fbq('track', 'CompleteRegistration');

        return true;
      } catch (err) {
        if (err.response) {
          console.log('caught err', err.response);

          this.processErrorData(err.response.data);

          this.$refs.registerForm.validate();
        } else {
          console.log('Error: ', err.message);
          throw err;
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
